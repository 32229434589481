
.card-1 {
    background-color: #e8e9ff;
    width: 10%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 30px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .card-1:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.2);
  }
.content1{
    display: flex;
    justify-content: space-around;
}
.icon-1{
padding: 7px 0px 0px 0px;    
color: red;
}
.cards-4{
    display: flex;
    justify-content: space-around;
}

span{
    font-weight: 600;
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
}
p{
    font-weight: 600;
    font-size: 17px;
}
/* .form-0{
    width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
} */

.wrapper {	
	margin-top: 80px;
  margin-bottom: 80px;
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #e8e9ff;
  border: 1px solid rgba(0,0,0,0.1);  

  .form-signin-heading,
	.checkbox {
	  margin-bottom: 30px;
	}



	.form-control {
	  position: relative;
	  font-size: 16px;
	  height: auto;
	  padding: 10px;
		@include box-sizing(border-box);

		&:focus {
		  z-index: 2;
		}
	}




}
